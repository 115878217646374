<template>
  <span class="subsite-accordion">
    <span
      :class="['subsite-accordion__title', { 'subsite-accordion__title--active': isOpen }]"
      tabindex="0"
      data-gtm-action="pdf collection"
      :data-gtm-label="props.title"
      @click.self="toggle"
      @keyup.enter="toggle"
      >{{ props.title }}</span
    >

    <transition name="slide">
      <div v-if="isOpen" class="subsite-accordion__items d-flex flex-col">
        <a
          v-for="(item, index) in props.items"
          :key="index"
          :href="item.url"
          class="subsite-accordion__items__link"
          target="_blank"
          data-gtm-action="link"
          :data-gtm-label="item.title"
          v-text="item.title"
        />
      </div>
    </transition>
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue'

interface IProps {
  title: string
  items: {
    title: string
    url: string
  }[]
}

const props = defineProps<IProps>()

const isOpen = ref(false)

const toggle = () => (isOpen.value = !isOpen.value)
</script>

<style lang="scss" scoped>
.subsite-accordion {
  cursor: pointer;

  &__title {
    position: relative;
    padding-right: $spacer-lg + $spacer-xs;

    &::after {
      $chevronIcon: &;
      content: '';
      -webkit-mask: url(/src/assets/svg/chevron.svg) center center no-repeat;
      background-color: $color-primary;
      height: $spacer-lg;
      width: $spacer-lg;
      margin-left: $spacer-sm;
      transform: rotate(180deg);
      transition: transform 0.3s ease;
      will-change: rotate;
      flex-shrink: 0;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
    }

    &--active::after {
      transform: rotate(0);
    }
  }

  &__items {
    margin-top: $spacer-xs;
    background-color: $color-tertiary;
    padding: 0 $spacer-md;

    &__link {
      display: block;
      margin: $spacer-sm 0;

      &:first-of-type {
        margin-top: $spacer-md;
      }

      &:last-of-type {
        margin-bottom: $spacer-md;
      }
    }
  }
}
</style>
